import logo from './logo.svg';
import './App.css';
import { app, analytics } from "./Config/firebase"; // Adjust the path as needed


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        
      </header>
    </div>
  );
}

export default App;
