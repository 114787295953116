// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBCXdNiLqQ-NOrodcPntdYcJc7Lk2TuEJ0",
  authDomain: "jacobster-510d2.firebaseapp.com",
  projectId: "jacobster-510d2",
  storageBucket: "jacobster-510d2.firebasestorage.app",
  messagingSenderId: "691163391971",
  appId: "1:691163391971:web:8d9fd250b49fcb164b62d6",
  measurementId: "G-MS4MBZ3EK4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);